/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components_v2/seo'
import LayoutV2 from '../components_v2/Layout'
import { Article, Container } from '../styles/misc'

const Privacy = ({ data, location }) => {
  const miscData = data.markdownRemark
  return (
    <LayoutV2 location={location}>
      <SEO
        title={miscData.frontmatter.title}
        description={miscData.frontmatter.description || miscData.excerpt}
        location={`/${miscData.frontmatter.page}/`}
      />
      <Container>
        <Article>
          <section dangerouslySetInnerHTML={{ __html: miscData.html }} />
        </Article>
      </Container>
    </LayoutV2>
  )
}

export default Privacy
export const miscQuery = graphql`
  query MiscPost($page: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { type: { eq: "misc" }, page: { eq: $page } }) {
      excerpt
      fields {
        slug
      }
      frontmatter {
        title
        description
        type
        page
      }
      html
    }
  }
`
